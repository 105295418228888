import { Grid } from "@mui/material";
import SpecialismCard from "../../components/SpecialismCard/SpecialismCard";
import meConstructionImage from "../../images/assets/MEConstruction.jpg";
import railSystemsImage from "../../images/assets/tunnel.jpeg";
import groundEngineeringImage from "../../images/assets/groundEngineering.jpg";

const SpecialismsCards = () => {
  return (
    <>
      <Grid
        item
        container
        xs={12}
        sm={4}
        md={4}
        lg={3}
        xl={3}
        justifyContent={"center"}
        padding={[2, 2, 2, 3, 3]}
      >
        <SpecialismCard
          headerText="Ground Engineering"
          image={groundEngineeringImage}
          specialismColor="#febe68"
          contentText="We provide white collar recruitment services across the entire Ground Engineering sector, covering all aspects of Piling, Geotechnical Engineering, Retaining Walls and Ground Improvement"
          specialismPageUrl="/Specialisms/Ground-Engineering"
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={4}
        md={4}
        lg={3}
        xl={3}
        justifyContent={"center"}
        padding={[2, 2, 2, 3, 3]}
      >
        <SpecialismCard
          headerText="M & E Construction"
          image={meConstructionImage}
          specialismColor="#ff8b53"
          contentText="We provide services to specialist M&E subcontractors who deliver large scale Mechanical, Electrical & Plumbing projects across the Advanced Facilities & Construction sectors."
          specialismPageUrl="/Specialisms/M-And-E-Construction"
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={4}
        md={4}
        lg={3}
        xl={3}
        justifyContent={"center"}
        padding={[2, 2, 2, 3, 3]}
      >
        <SpecialismCard
          headerText="Rail Systems"
          image={railSystemsImage}
          specialismColor="rgb(84, 219, 163)"
          contentText="We provide services to Rail System Infrastructure specialist sub-contractors, main contractors, and Consultancies. Disciplines include: S&T, Electrification, P-Way, and Civils."
          specialismPageUrl="/Specialisms/Rail-Systems"
        />
      </Grid>
    </>
  );
};
export default SpecialismsCards;
