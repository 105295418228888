import { Typography, Grid } from "@mui/material";
import CountUp from "react-countup";

const AnimatedStat = ({
  statname,
  statValue,
  statColor,
  suffix,
  startValue,
}) => {
  return (
    <Grid
      item
      container
      xs={4}
      sm={4}
      md={4}
      lg={3}
      xl={3}
      padding={1}
      wrap="wrap"
      sx={{
        justifyContent: "center",
        alignItems: "stretch",
        overflowWrap: "anywhere",
        position: "relative",
        height: "100%",
        maxHeight: "100%",
        "&::before": {
          position: "absolute",
          content: '""',
          width: "1px",
          height: "100%",
          backgroundColor: "#000",
          left: 0,
        },
      }}
    >
      <Typography
        sx={{
          fontSize: "2.5rem",
          width: "100%",
          textAlign: "center",
          color: `${statColor}`,
          padding: 0,
          margin: 0,
          fontFamily: "Poppins",
          fontWeight: 300,
        }}
      >
        <CountUp
          start={startValue ?? 0}
          end={statValue}
          duration={7}
          enableScrollSpy
          scrollSpyDelay={3}
          suffix={suffix ?? undefined}
          useGrouping={false}
        />
      </Typography>
      <Typography
        sx={{
          width: "100%",
          textAlign: "center",
          color: "#1e1e1e",
          fontFamily: "Poppins",
          fontWeight: 600,
        }}
      >
        {statname}
      </Typography>
    </Grid>
  );
};
export default AnimatedStat;
