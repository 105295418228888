import { Grid, Typography, CardHeader, CardContent, Card } from "@mui/material";

export const JobsCard = ({
  jobTitle,
  vacancyType,
  specialism,
  location,
  salaryLow,
  salaryHigh,
  benefits,
  description,
  isSummaryCard,
  jobReference,
  gridBackgroundColor,
  cardBackgroundColor,
}) => {
  const formatSpecialismString = (specialism) => {
    // Convert capitals to lowercase
    const lowerCaseSpecialism = specialism.replace(
      /[A-Z]/g,
      function (capitalLetter) {
        return capitalLetter.toLowerCase();
      }
    );

    // Remove spaces
    const formattedSpecialism = lowerCaseSpecialism.replace(/ /g, "");
    // return formatted specialism name correspondor to color block
    if (formattedSpecialism.includes("construction")) return "construction";
    if (formattedSpecialism.includes("rail")) return "railsystems";
    if (formattedSpecialism.includes("ground")) return "groundengineering";
    else return "default";
  };

  const getTrimmedDescription = (descriptionText) => {
    if (descriptionText.length <= 100) return descriptionText;

    const postCharLimitFullStopIndex = descriptionText.indexOf(".", 100);

    if (postCharLimitFullStopIndex !== -1) {
      return descriptionText.substring(0, postCharLimitFullStopIndex + 1);
    }
    const preCharLimitWordEnd = descriptionText.lastIndexOf(" ", 100);
    return descriptionText.substring(0, preCharLimitWordEnd) + "...";
  };

  const formatDescription = (description) => {
    const sentences = description
      ? description
          .split(/(?<=\.|:)/)
          .filter(Boolean)
          .map((sentence, index) => (
            <Typography
              key={index}
              align="justify"
              sx={{
                padding: "0.3rem",
                fontFamily: "Poppins",
                fontSize: "0.7rem",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              {sentence.trim()}
            </Typography>
          ))
      : "Not Yet Available";
    return sentences;
  };

  const hasShortenedDescription = Boolean(isSummaryCard && description)
    ? getTrimmedDescription(description)
    : formatDescription(description);

  const colorSpecialismSelector = specialism
    ? formatSpecialismString(specialism)
    : "default";

  const color = {
    railsystems: "rgb(84, 219, 163)",
    groundengineering: "#febe68",
    construction: "#ff8b53",
    default: "#1e1e1e",
  };


  const defaultSalaryDisplay =
    !salaryLow && !salaryHigh
      ? "£££ Competitive"
      : `£${salaryLow ?? "Competitive"} - £${salaryHigh ?? "Competitive"}`;

  return (
    <Grid
      item
      xs={isSummaryCard ? 6 : 12}
      sm={isSummaryCard ? 6 : 12}
      md={isSummaryCard ? 4 : 6}
      lg={isSummaryCard ? 3 : 6}
      xl={isSummaryCard ? 3 : 6}
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        backgroundColor: gridBackgroundColor ?? "#ffffff",
        padding: 3,
      }}
    >
      <Card sx={{ backgroundColor: cardBackgroundColor ?? "#f4f4f4" }}>
        <CardHeader
          title={
            <Typography
              align="left"
              variant="h5"
              paddingY={1}
              sx={{
                textDecoration: `1px solid underline ${color[colorSpecialismSelector]}`,
                fontFamily: "Poppins",
                fontWeight: 300,
              }}
            >
              {jobTitle}
            </Typography>
          }
          subheader={[
            <Typography
              variant="body2"
              align="right"
              color="#1e1e1e"
              sx={{
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            >
              {isSummaryCard ? undefined : specialism} <br />{" "}
              {defaultSalaryDisplay} <br /> {benefits}
            </Typography>,
            <Typography
              align="right"
              sx={{
                textDecoration: `1px solid underline ${color[colorSpecialismSelector]}`,
                fontFamily: "Poppins",
                fontWeight: 300,
              }}
            >
              {vacancyType}
            </Typography>,
            <Typography
              variant="body2"
              align="right"
              sx={{
                color: "#1e1e1e",
                fontFamily: "Poppins",
                fontWeight: 300,
              }}
            >
              {location}
            </Typography>,
            jobReference ? (
              <Typography
                variant="body2"
                align="right"
                sx={{
                  color: "#1e1e1e",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                Reference: {jobReference}
              </Typography>
            ) : undefined,
          ]}
        ></CardHeader>
        <CardContent>
          <Typography
            align="justify"
            sx={{
              padding: "0.3rem",
              fontFamily: "Poppins",
              fontSize: "0.7rem",
              fontWeight: 600,
              fontStyle: "italic",
            }}
          >
            {hasShortenedDescription}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
