import "../SiteLinksList/SiteLinksList.scss";
import siteData from "../../data/siteInputData";
import { Link } from "react-router-dom";

const SiteLinksList = ({ pageLocation }) => {
  const { navigationLinksList } = siteData;
  const menuLinks = navigationLinksList.map((element, index) => (
    <Link
      key={index}
      className={`site-link-list__link site-link-list__link--${element} site-link-list__link--${pageLocation}`}
      to={element === "Home" ? "/" : `/${element.replace(/\s/g, "")}`}
    >
      {element}
    </Link>
  ));
  return menuLinks;
};
export default SiteLinksList;
