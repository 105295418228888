import React, { useState } from "react";
import {
  Card,
  Typography,
  CardMedia,
  CardContent,
  CardActionArea,
  Link,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const SectorTitleCard = ({
  image,
  sectorTitle,
  highlightColor,
  URLSuffix,
  themeColorName,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const overlayStyle = {
    position: "absolute",
    borderRadius: "1rem",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(232, 232, 232, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: isHovered ? 1 : 0,
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: isMediumScreen ? "20rem" : "13rem",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={0}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
    >
      <CardActionArea
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          // Needed for overlay
        }}
        disableRipple
      >
        <CardMedia
          component="img"
          image={image}
          sx={{
            height: "70%",
            borderRadius: "1rem",
            position: "relative",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "flex-start",
          }}
        >
          <div style={overlayStyle}>
            <Button variant="contained" color="primary" href={"/Vacancies"}>
              Live Vacancies
            </Button>
            <Button
              variant="contained"
              color={themeColorName}
              href={`/Specialisms/${URLSuffix}`}
              sx={{ marginLeft: 2 }}
              LinkComponent={Link}
            >
              Read More
            </Button>
          </div>
          <Typography
            component={Link}
            href={`/Specialisms/${URLSuffix}`}
            variant="h6"
            fontWeight="600"
            width="100%"
            sx={{
              height: "3rem",
              textDecoration: `2px solid underline ${highlightColor}`,
            }}
          >
            {sectorTitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SectorTitleCard;
