import { useState, useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { Grid, TextField } from "@mui/material";

const SearchBar = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");
  // const debouncedSearchText = useDebounce(searchText, 300);

  // useEffect(() => {
  //   onSearch(debouncedSearchText);
  // }, [debouncedSearchText, onSearch]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <TextField
        color="primary"
        autoComplete="off"
        fullWidth
        variant="filled"
        value={searchText}
        onChange={handleSearchChange}
        label="Search"
        sx={{ backgroundColor: "#ffffff" }}
      />
    </Grid>
  );
};

export default SearchBar;
