export const dummyData = [
  {
    jobReference: 1,
    jobTitle: "Quantity Surveyor ",
    vacancyType: "Permanent",
    sector: "Ground Engineering",
    location: "England (Midlands)",
    salaryLow: 35000,
    salaryHigh: 55000,
    benefits: "Car + Bonus + Package ",
    description:
      "Williams Management Consulting are actively searching for a Quantity Surveyor to join one of our repeat specialist - piling & geotechnical engineering contracting clients in the Midlands (England). \r\n\r\nDesirable experience include:\r\n - Experience working as a Quantity Surveyor for either a main contracting or sub contracting entity with project exposure to piling or geotechnical techniques/packages of work. \r\n- Experience/exposure to NEC3/4, JCT or bespoke forms of contracts.\r\n- Applications for payment, producing BOQ's and management of subcontract accounts.\r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, bonuses & package. \r\n- Fantastic company culture with a highly focused employee centric approach.\r\n- Our client is passionate about their employees development and will invest heavily in this area. \r\n- Flexible/hybrid working.  ",
  },
  {
    jobReference: 2,
    jobTitle: "Senior Quantity Surveyor ",
    vacancyType: "Permanent",
    location: "Bristol",
    sector: "Ground Engineering",
    salaryLow: 55000,
    salaryHigh: 75000,
    benefits: "Car + Bonus + Package ",
    description:
      "Williams Management Consulting are actively searching for a Senior Quantity Surveyor to join one of our repeat specialist - piling & geotechnical engineering contracting clients in the Essex area. \r\n\r\nDesirable experience include:\r\n - Experience working as a Senior Quantity Surveyor for either a main contracting or sub contracting entity with project exposure to piling or geotechnical techniques/packages of work. \r\n- Experience running projects on NEC3/4 or JCT forms of contracts.\r\n- Agreeing T&C/contracts with prospective clientele and subcontractors.\r\n- CVRs, stakeholder management, change control, applications for payment, producing BOQ's and management of subcontract accounts.\r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, bonuses & package. \r\n- Fantastic company culture with a highly focused employee centric approach.\r\n- Our client is passionate about their employees development and will invest heavily in this area. \r\n- Flexible working hybrid/FT remote.  ",
  },
  {
    jobReference: 3,
    jobTitle: "Managing Quantity Surveyor",
    vacancyType: "Permanent",
    location: "England (Midlands)",
    sector: "Rail Systems",
    salaryLow: 65000,
    salaryHigh: 80000,
    benefits: "Car + Bonus + Package ",
    description:
      "Williams Management Consulting are actively searching for a Managing Quantity Surveyor to join one of our repeat specialist - piling & geotechnical engineering contracting clients in the Midlands (England). \r\n\r\nDesirable experience include:\r\n - Experience working as a Senior Quantity Surveyor / Managing Quantity Surveyor / Commercial Manager for either a main contracting or sub contracting entity with project exposure to piling or geotechnical techniques/packages of work. \r\n- Experience in managing a team of staff with examples of leadership qualities.  \r\n- Experience running projects on NEC3/4 or JCT forms of contracts.\r\n- Agreeing T&C/contracts with prospective clientele and subcontractors.\r\n- Running CVRs, stakeholder management, change control, holding 1-2-1 training sessions with employees,  managing staff in person and virtually, applications for payment, producing BOQ's and management of subcontract accounts.\r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, bonuses & package. \r\n- Fantastic company culture with a highly focused employee centric approach.\r\n- Our client is passionate about their employees development and will invest heavily in this area. \r\n- Flexible working hybrid/FT remote.  ",
  },
  {
    jobReference: 4,
    jobTitle: "Technical Manager",
    vacancyType: "Permanent",
    location: "England (North west)",
    sector: "Rail Systems",
    salaryLow: 50000,
    salaryHigh: 65000,
    benefits: "Car + Bonus + Package ",
    description:
      "Williams Management Consulting are actively searching for a Technical Manager to join one of our repeat specialist - piling contracting clients in the North West of England. \r\n\r\nDesirable experience include:\r\n - Experience working as a Senior Design Engineer / Design Manager / Technical Manager for a piling specialist with exposure to any/all of the following piling disciplines - CFA, Rotary, Driven, Mini Piles or Bored. \r\n- Experience both with the Pre-Construction (Estimating) and Technical Engineering/Design of the full project lifecycle.\r\n- Technical engineering background to assist prospective clientele in producing delivery plans and contract formulation.  \r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, annual bonus & package. \r\n- Fantastic company culture with a highly focused employee centric approach.\r\n- Our client is passionate about their employees development and will sponsor heavily in this area. \r\n- Flexible working hybrid/FT remote.  ",
  },
  {
    jobReference: 5,
    jobTitle: "Estimator (CFA)",
    vacancyType: "Permanent",
    location: "England (North west)",
    sector: "M&E Construction",
    salaryLow: 30000,
    salaryHigh: 45000,
    benefits: "Car + Bonus + Package ",
    description:
      "Williams Management Consulting are actively searching for an Estimator to join one of our repeat specialist - piling contracting clients in the North West of England. \r\n\r\nDesirable experience include:\r\n - Experience working as an Estimator for a piling contractor with exposure to any/all of the following piling disciplines - CFA, Driven or Mini Piles. \r\n- Experience producing tender submissions and pricing projects from first principals. \r\n- Degree qualified within a relevant subject area - or equivalent. \r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, annual bonus & package. \r\n- Fantastic company culture with a highly focused employee centric approach.\r\n- Our client is passionate about their employees development and will sponsor heavily in this area. \r\n- Flexible/hybrid working.  ",
  },
  {
    jobTitle: "Estimator (Virbo/GI)",
    vacancyType: "Permanent",
    location: "England (North west)",
    sector: "M&E Construction",
    salaryLow: 30000,
    salaryHigh: 45000,
    benefits: "Car + Bonus + Package ",
    description:
      "Williams Management Consulting are actively searching for an Estimator  to join one of our repeat specialist - Vibro / Ground Improvement contracting clients in the North West of England. \r\n\r\nDesirable experience include:\r\n - Experience working as an Estimator for a Vibro/Ground Improvement contractor with exposure to any/all of the following piling disciplines - Vibro Techniques or Ground Improvement, \r\n- Experience producing tender submissions and pricing projects from first principals. \r\n- Degree qualified within a relevant subject area - or equivalent. \r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, annual bonus & package. \r\n- Fantastic company culture with a highly focused employee centric approach.\r\n- Our client is passionate about their employees development and will sponsor heavily in this area. \r\n- Flexible/hybrid working.  ",
  },
  {
    jobReference: 6,
    jobTitle: "Supervisor ",
    vacancyType: "Temporary",
    location: "England - Nationwide",
    salaryLow: 30000,
    salaryHigh: 50000,
    benefits: "Car + Package ",
    description:
      "Williams Management Consulting are actively searching for a Supervisor  to join one of our repeat specialist - Piling contracting clients who deliver projects nationally across England. \r\n\r\nDesirable experience include:\r\n - Experience working as an Supervisor / Site Engineer for a piling contractor with exposure to any/all of the following piling disciplines - CFA, Driven, Rotary or Bored. \r\n- CSCS, SMSTS and NEBOSH General Construction.  \r\n\r\nBenefits include: \r\n- Highly competitive base salary, company vehicle, expenses, fuel card, overtime & package. \r\n- Internationally renowned contractor which have an abundance of work and career growth opportunities.  \r\n- Our client is passionate about their employees development and will sponsor heavily in this area.",
  },
  {
    jobReference: 7,
    jobTitle: "Senior Design Engineer / Design Manager",
    vacancyType: "Permanent",
    location: "England (South east)",
    sector: "M&E Construction",
    salaryLow: 55000,
    salaryHigh: 75000,
    benefits: "Car + Package ",
    description:
      "Williams Management Consulting are actively searching for a Senior Design Engineer or Design Manager  to join one of our London / South East  specialist - piling contracting clients. \r\n\r\nDesirable experience include:\r\n - Experience working as a Senior Design Engineer / Design Manager for a piling specialist with exposure to any/all of the following piling disciplines - CFA, Rotary, Driven, Mini Piles or Bored. \r\n- Experience reviewing drawings, producing calculations and working collaboratively with colleagues to develop engineering solutions to overcome current and potential challenges. \r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, annual bonus & package. \r\n- Our client is passionate about their employees development and will sponsor heavily in this area. \r\n- Flexible/hybrid working.  ",
  },
  {
    jobReference: 8,
    jobTitle: "Estimator (CFA)",
    vacancyType: "Permanent",
    location: "Surrey",
    sector: "M&E Construction",
    salaryLow: 45000,
    salaryHigh: 75000,
    benefits: "Car + Package ",
    description:
      "Williams Management Consulting are actively searching for an Estimator  to join one of our repeat specialist - piling contracting clients in the Surrey area. \r\n\r\nDesirable experience include:\r\n - Experience working as an Estimator for a piling contractor.\r\n- Experience producing tender submissions and pricing projects from first principals. \r\n- Degree qualified within a relevant subject area - or equivalent. \r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance & package. \r\n- Fantastic company culture with a highly focused employee centric approach.\r\n- Our client is passionate about their employees development and will sponsor heavily in this area. \r\n- Flexible/hybrid working.  ",
  },
  {
    jobReference: 9,
    jobTitle: "Senior Design Engineer",
    vacancyType: "Permanent",
    location: "Hertfordshire",
    sector: "Ground Engineering",
    salaryLow: 45000,
    salaryHigh: 60000,
    benefits: "Package ",
    description:
      "Williams Management Consulting are actively searching for a Senior Design Engineer to join one of our Hertfordshire   specialist - piling  clients. \r\n\r\nDesirable experience include:\r\n - Experience working as a Design Engineer or Senior Design Engineer for a specialist piling contractor or consultancy.  \r\n- Experience reviewing drawings, producing calculations and working collaboratively with colleagues to develop engineering solutions to overcome current and potential challenges. \r\n\r\nBenefits include: \r\n- Highly competitive base salary, car allowance, annual bonus & package. \r\n- Our client is passionate about their employees development and will sponsor heavily in this area. \r\n- Flexible/hybrid working.  ",
  },
  {
    jobReference: 10,
    jobTitle: "HV Estimator",
    vacancyType: "Contract",
    sector: "Ground Engineering",
    location: "Scotland - Remote",
    salaryLow: 350,
    salaryHigh: 500,
    benefits: "NA",
  },
  {
    jobReference: 11,
    jobTitle: "Senior Quantity Surveyor ",
    vacancyType: "Contract",
    sector: "Ground Engineering",
    location: "Essex",
    salaryLow: 350,
    salaryHigh: 450,
    benefits: "NA",
  },
  {
    jobReference: 12, 
    jobTitle: "Project Quantity Surveyor",
    vacancyType: "Contract",
    sector: "M&E Construction",
    location: "Essex",
    salaryLow: 350,
    salaryHigh: 450,
    benefits: "NA",
  },
  {
    jobReference: 13, 
    jobTitle: "Intermediate Quantity Surveyor ",
    vacancyType: "Contract",
    sector: "Rail Systems",
    location: "Essex",
    salaryLow: 350,
    salaryHigh: 450,
    benefits: "NA",
  },
];