import { Grid, Paper, Typography, useMediaQuery, Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import SignUp from "../../components/SignUp/SignUp";
import { useTheme } from "@emotion/react";
import ContactInformation from "../../components/ContactInformation/ContactInformation";
import DecorativeBanner from "../../components/DecorativeBanner/DecorativeBanner";
import "./ContactUs.scss";
import trainStation from "../../../src/images/assets/trainStation.jpeg";
import ImageBanner from "../../containers/ImageBanner/ImageBanner";

const ContactUs = () => {
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Layout>
      <Box
        className="background"
        sx={{ backgroundColor: theme.palette.primary.greyMedium }}
      >
        <Grid container justifyContent={"center"}>
          <ImageBanner image={trainStation} title="Contact Us" />

          <DecorativeBanner />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
            <Paper
              variant="elevation"
              elevation={8}
              sx={{
                backgroundImage:
                  "repeating-linear-gradient(45deg, transparent, transparent 100px, #ff8b53 100px, #ff8b53 200px)",
                backgroundPosition: "right 0",
                backgroundSize: "8rem 100%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  padding: 3,
                  color: "#1e1e1e",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "70%",
                }}
              >
                Striving to be better than yesterday,<br></br> delivering
                exceptional service today
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={4}
            xl={4}
            container
            alignContent={"center"}
            padding={4}
            justifyContent={"center"}
          >
            <Box
              flex
              padding={1}
              justifyContent={"center"}
              sx={{ backgroundColor: `${theme.palette.primary.greyLight}` }}
            >
              <ContactInformation />
            </Box>
          </Grid>
          <Grid
            item
            padding={medium ? 4 : 2}
            xs={12}
            sm={6}
            md={5}
            lg={4}
            xl={4}
          >
            <SignUp />
          </Grid>
          <Grid
            item
            padding={medium ? 4 : 2}
            xs={12}
            sm={12}
            md={3}
            lg={4}
            xl={4}
          >
            <iframe
              title="location-map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2484.4539520932763!2d-3.1778796!3d51.4865366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb326e1c5a309%3A0xeb12e6c163226f61!2sWilliams%20Management%20Consulting!5e0!3m2!1sen!2suk!4v1693750001669!5m2!1sen!2suk"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullscreen="false"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default ContactUs;
