import "./Banner.scss";
import NavMenu from "../NavMenu/NavMenu";
import logo from "../../images/logos/stackedLogoBlack.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import SocialLinks from "../SocialLinks/SocialLinks";
import { ImCross } from "react-icons/im";
import MyButton from "../MyButton/MyButton";
import { Link } from "@mui/material";

const Banner = ({ dropdownState, setdropDownState }) => {
  return (
    <div className="banner">
      <div className="banner__logo-container">
        <Link href="/">
          <img className="banner__logo" src={logo} alt="The HardmanW" />
        </Link>
      </div>
      <div className="banner__nav-menu-container">
        <div className="banner__links-container">
          <SocialLinks iconColor="blue" iconSize="default" block="header" />
          <MyButton
            buttonText="View Vacancies"
            linkId={"/Vacancies"}
            isNavButton
          />
        </div>
        <NavMenu pageLocation="header" />
      </div>
      {!dropdownState && (
        <GiHamburgerMenu
          className="banner__hamburger-menu"
          onClick={() => setdropDownState(true)}
        />
      )}
      {dropdownState && (
        <>
          <ImCross
            className="banner_dropdown-menu-close"
            onClick={() => setdropDownState(false)}
          />
        </>
      )}
    </div>
  );
};
export default Banner;
