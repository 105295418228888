import "./SocialLinks.scss";
import { BsLinkedin } from "react-icons/bs";
import { Link } from "@mui/material";
const SocialLinks = ({ iconColor, iconSize, block }) => {
  let socialLinksContainerClass = `social-links-container social-links-container--${block}`;
  let socialLinksClassName = `social-links social-links--${iconColor} social-links--${iconSize}`;
  return (
    <div className={socialLinksContainerClass}>
      <Link
        href="https://www.linkedin.com/company/williamsmanagementconsulting"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsLinkedin className={socialLinksClassName} />
      </Link>
    </div>
  );
};

export default SocialLinks;
