import {
  Box,
  Grid,
  ScopedCssBaseline,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageBanner from "../../containers/ImageBanner/ImageBanner";
import tunnel from "../../images/assets/tunnel.jpeg";
import DecorativeBanner from "../../components/DecorativeBanner/DecorativeBanner";
import { useState, useCallback } from "react";
import FilterAccordion from "../../components/FilterAccordion/FilterAccordion";
import { dummyData } from "../../dummyData";
import SearchBar from "./SearchBar/SearchBar";
import { Container } from "@mui/system";
import { JobsCard } from "../../components/JobCard/JobsCard";

const Jobs = () => {
  const extractFilterOptions = (jobsData, filterProperty) => {
    const uniqueOptions = [
      ...new Set(
        jobsData
          .map((jobListing) => {
            // if(jobListing[filterProperty] === "M&E Construction") {
            //   return "M&E Construction"
            // }
            return jobListing[filterProperty];
          })
          .filter((jobPropertyValue) => jobPropertyValue !== undefined)
      ),
    ];
    return uniqueOptions;
  };
  const filterInputoptions = {
    vacancyType: extractFilterOptions(dummyData, "vacancyType"),
    location: extractFilterOptions(dummyData, "location"),
    sector: extractFilterOptions(dummyData, "sector"),
  };

  const [searchText, setSearchText] = useState("");
  const [checkedOptions, setCheckedOptions] = useState({
    vacancyType: [],
    location: [],
    sector: [],
  });

  const handleSearchChange = useCallback(
    (searchValue) => {
      setSearchText(searchValue);
    },
    [setSearchText]
  );

  const handleFilterChange = useCallback(
    (title, newCheckedOptions) => {
      setCheckedOptions((prevCheckedOptions) => ({
        ...prevCheckedOptions,
        [title]: newCheckedOptions,
      }));
    },
    [setCheckedOptions]
  );

  const filteredItems = dummyData.filter((item) => {
    const { vacancyType, location, sector } = checkedOptions;
    return (
      (vacancyType.length === 0 || vacancyType.includes(item.vacancyType)) &&
      (location.length === 0 ||
        location.some((checkedLocation) =>
          item.location.toLowerCase().includes(checkedLocation.toLowerCase())
        )) &&
      (sector.length === 0 || sector.includes(item?.sector ?? "No sector"))
    );
  });
  const searchedItems = filteredItems.filter((item) => {
    if (searchText?.trim() === "") {
      return true;
    }
    const searchWords = searchText.trim().toLowerCase().split(" ");
    const jobTitleLower = item?.jobTitle?.trim().toLowerCase() || "";

    const descriptionLower = item?.description?.trim().toLowerCase() || "";

    return searchWords.every((word) => {
      // Check if the word is followed by a space in the original searchText
      const isFollowedBySpace =
        searchText
          .trim()
          .toLowerCase()
          .indexOf(word + " ") !== -1;

      if (isFollowedBySpace) {
        // Match whole word
        const wordPattern = new RegExp(`\\b${word}\\b`);
        return (
          wordPattern.test(jobTitleLower) || wordPattern.test(descriptionLower)
        );
      } else {
        // Allow for substring match
        return jobTitleLower.includes(word) || descriptionLower.includes(word);
      }
    });
  });

  const handleFilterReset = () => {
    setCheckedOptions({
      vacancyType: [],
      location: [],
      sector: [],
    });
  };

  // media query should - update sticky position to further down,  change filters to default expanded, increase container with to 80% padding 3 on filters
  // need to add the stickyness to accordion on md screen
  const theme = useTheme();
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const filterPadding = isMediumScreen ? 3 : 0;
  const accordionStyling = Boolean(isMediumScreen);
  return (
    <ScopedCssBaseline>
      <ImageBanner image={tunnel} title="Our Live Vacancies" />
      <DecorativeBanner />
      <Container disableGutters sx={{}}>
        <Grid container>
          <Grid
            item
            container
            sx={{}}
            justifyContent={"center"}
            id="bethhhh"
            md={3}
            lg={3}
            xl={3}
            padding={filterPadding}
          >
            <Box sx={{ position: "sticky", top: 0, zIndex: 100 }}>
              <Grid container sx={{}} justifyContent="center">
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  p={1}
                >
                  <SearchBar onSearch={handleSearchChange} />
                  <Accordion
                    defaultExpanded={accordionStyling}
                    sx={{
                      width: "100%",
                      backgroundColor: "#e8e8e8",
                      padding: "0.2rem",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ width: "100%" }}
                    >
                      <Typography variant="body1">
                        Filter your search
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.entries(filterInputoptions).map(
                        ([title, filterOptions]) => (
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            key={title}
                          >
                            <FilterAccordion
                              isExpandedProp={accordionStyling}
                              title={title}
                              filterOptions={filterOptions}
                              checkedOptions={checkedOptions[title]}
                              onChange={(newCheckedOptions) =>
                                handleFilterChange(title, newCheckedOptions)
                              }
                            />
                          </Grid>
                        )
                      )}
                    </AccordionDetails>
                    <Button
                      onClick={() => handleFilterReset()}
                      variant="contained"
                      color="inherit"
                      disableRipple
                      size="small"
                      fullWidth
                      sx={{
                        margin: "auto",
                        textDecoration: `1px solid underline #ff8b53`,
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Accordion>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container sx={{}} id="jimmm" md={9} lg={9} xl={9}>
            <Box width={"100%"}>
              <Typography align="center">{`Matching Vacancies: ${searchedItems.length}`}</Typography>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                justifyContent="center"
                alignItems="baseline"
              >
                {searchedItems.map((item) => (
                  <JobsCard
                    key={item?.jobReference}
                    jobTitle={item?.jobTitle}
                    vacancyType={item?.vacancyType}
                    specialism={item?.sector}
                    location={item?.location}
                    salaryLow={item?.salaryLow}
                    salaryHigh={item?.salaryHigh}
                    benefits={item?.benefits}
                    description={item?.description}
                    jobReference={item?.jobReference}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ScopedCssBaseline>
  );
};
export default Jobs;
