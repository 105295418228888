import { createTheme } from "@mui/material/styles";

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: "#1e1e1e",
      white: "#ffffff",
      greyLight: "#f4f4f4",
      greyMedium: "#e8e8e8",
      secondary: "#ff8b53",
    },
    secondary: {
      main: "#ff8b53",
    },
    tertiary: {
      main: "#febe68",
    },
    quaternary: {
      main: "#54dba3",
    },
    quinary: {
      main: "#ffffff",
    },

    highlight: {
      // construction
      orange: "#ff8b53",
      // geotech
      orangeMedium: "#febe68",
      // rail
      orangeLight: "#ffc85c",
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif !important",
    h1: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
    },
    h6: {
      fontWeight: 300,
    },
    body1: {
      fontWeight: 600,
    },
    body2: {
      fontWeight: 300,
    },
  },
});

export default theme;

