import { Grid, Typography } from "@mui/material";
import Image from "mui-image";

const ImageBanner = ({ image, title }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ position: "relative" }}
      alignItems={"center"}
      height={["10vh", "10vh", "20vh", "20vh"]}
    >
      <Image src={image} alt="A Person Welding on a Rail Construction site" />
      <Typography
        variant="h2"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 600,
          color: "#ffffff",
          position: "absolute",
          bottom: "5%",
          left: "5%",
          textShadow: "0px 0px 2px #1e1e1e",
          fontSize: ["2rem", "2rem", "3rem", "3rem"],
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
};
export default ImageBanner;
