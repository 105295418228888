import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import emailjs from "@emailjs/browser";

export default function SignUp() {
  const [showAlert, setShowAlert] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width:2560px)");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      email: data.get("email"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      contactNumber: data.get("contactNumber"),
      message: data.get("message"),
    };
    await emailjs
      .send(
        "service_4yyltrm",
        "template_noa0uuh",
        formData,
        "OZOID-XZpF-rm36MD"
      )
      .then((response) => {
        setShowAlert(true);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
    event.target.reset();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [showAlert]);

  return (
    <Container component="main" maxWidth={isLargeScreen ? "lg" : "md"}>
      <CssBaseline />
      <Box
        padding={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f4f4f4",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          zIndex={2}
          sx={{
            backgroundColor: "#f4f4f4",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                fullWidth
                name="contactNumber"
                label="Contact Number"
                type="number"
                id="contactNumber"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                name="message"
                label="Message"
                type="text"
                id="message"
                size="large"
                multiline
              />
            </Grid>
          </Grid>

          {showAlert ? (
            <Alert severity="success" sx={{ mt: 2, mb: 1, width: "100%" }}>
              Email sent successfully!
            </Alert>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 1, bgcolor: "#febe68" }}
            >
              Send Message
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
}
