import Layout from "../../components/Layout/Layout";
import SpecialismHexagon from "../../components/SpecialismHexagon/SpecialismHexagon";
import "./HeroSection.scss";
import { Link } from "@mui/material";
const HeroSection = () => {
  return (
    <Layout>
      <section className="hero-section">
        <div className="hero-section__text-container">
          <h1 className="hero-section__header">Be Better Than Yesterday</h1>
          <h2 className="hero-section__sub-header">
            Driven by continuous development, delivering exceptional
            recruitment.
          </h2>
        </div>
        {/* refactor later to a foreach on an array of objects that maps colours and data then generates hexagon based on inputs. */}
        <div className="hero-section__icon-container">
          <Link href="/Specialisms/Ground-Engineering">
            <SpecialismHexagon
              title="Ground Engineering"
              tagline="Solid foundations, strong prospects"
              color="#febe68"
            />
          </Link>
          <Link href="/Specialisms/M-And-E-Construction">
            <SpecialismHexagon
              title="M & E Construction"
              tagline="Building a better tomorrow"
              color="#ff8b53"
            />
          </Link>
          <Link href="/Specialisms/Rail-Systems">
            <SpecialismHexagon
              title="Rail Systems"
              tagline="Laying the tracks for success"
              color="rgb(84, 219, 163)"
            />
          </Link>
        </div>
      </section>
    </Layout>
  );
};
export default HeroSection;
