import "./Home.scss";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../containers/HeroSection/HeroSection";
import { CssBaseline, Grid, Typography } from "@mui/material";
import DecorativeBanner from "../../components/DecorativeBanner/DecorativeBanner";
import SpecialismsCards from "../../containers/SpecialismCards/SpecialismCards";
import ReviewQuotes from "../../containers/ReviewQuotes/ReviewQuotes";
import StatsContainer from "../../containers/StatsContainer/StatsContainer";
import IntroductionContainer from "../../containers/IntroductionContainer/IntroductionContainer";
const Home = () => {
  return (
    <Layout>
      <CssBaseline />
      <HeroSection />
      <DecorativeBanner />
      <Grid container>
        <StatsContainer />
        <DecorativeBanner color="lightYellow" />
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          alignItems="baseline"
        >
          <IntroductionContainer />
        </Grid>
        <DecorativeBanner color="black" />
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          p={4}
          sx={{
            backgroundColor: "#ffffff",
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "space-evenly",
              xl: "space-evenly",
            },
          }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            px={2}
            sx={{
              color: "#1e1e1e",
              fontFamily: "Poppins",
              fontWeight: 600,
              textDecoration: "underline #ff8b53",
              width: "100%",
              textAlign: "center",
            }}
          >
            Our Specialisms
            {/* make a card component */}
          </Typography>
          <SpecialismsCards />
        </Grid>
        <DecorativeBanner />

        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          px={[4, 4, 6, 6, 8]}
          py={[2, 2, 2, 2, 2]}
          sx={{ backgroundColor: "#1e1e1e" }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            px={2}
            sx={{
              color: "#ffffff",
              fontFamily: "Poppins",
              fontWeight: 600,
              textDecoration: "underline #febe68",
              width: "100%",
              textAlign: "center",
            }}
          >
            Testimonials
            {/* make a card component */}
          </Typography>
          <ReviewQuotes />
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ position: "relative" }}
          alignItems={"center"}
          height={["5vh", "10vh", "10vh", "10vh"]}
        >
          <Image
            src={skyscrapers}
            alt="A Person Welding on a Rail Construction site"
            sx={{ objectFit: "cover", objectPosition: "center bottom" }}
          />
        </Grid> */}

        {/* <DecorativeBanner /> */}
      </Grid>
    </Layout>
  );
};
export default Home;
