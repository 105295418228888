import "./Footer.scss";
import SiteLinksList from "../../components/SiteLinksList/SiteLinksList";
import blackLogo from "../../images/logos/stackedLogoBlack.svg";
import ContactInformation from "../../components/ContactInformation/ContactInformation";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import Layout from "../../components/Layout/Layout";
const Footer = () => {
  return (
    <Layout>
      <footer className="footer">
        <div className="footer__tagline-container">
          <div className="footer_logo-container">
            <img
              className="footer__logo"
              src={blackLogo}
              alt="The WilliamsManagementConsulting Logo"
            />
          </div>
          <h5 className="footer__tagline">Be Better Than Yesterday</h5>
        </div>
        <div className="footer__site-links-container">
          <h5>Quick Links</h5>
          <div>
            <SiteLinksList pageLocation="footer" />
          </div>
        </div>
        <div className="footer__contact-us-container">
          <ContactInformation hasHeader />
        </div>
        <div className="footer__social-links-container">
          <SocialLinks iconColor="blue-green" block="footer" />
        </div>
      </footer>
    </Layout>
  );
};
export default Footer;
