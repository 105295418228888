import {
  ScopedCssBaseline,
  Box,
  Grid,
  Typography,
  Link,
  Button,
} from "@mui/material";
import { StarSharp } from "@mui/icons-material";
import dam from "../../../src/images/assets/dam.jpeg";
import DecorativeBanner from "../../components/DecorativeBanner/DecorativeBanner";
import SpecialismsCards from "../../containers/SpecialismCards/SpecialismCards";
import ImageBanner from "../../containers/ImageBanner/ImageBanner";
import BulletPointList from "../Specialisms/GroundEngineering/bulletpoints";
const AboutUs = () => {
  const aboutUsSubheader = (
    <Typography
      variant="h5"
      px={4}
      sx={{
        color: "#1e1e1e",
        width: "100%",
        // textAlign: `justify`,
        fontWeight: 600,
        marginTop: "0.3rem",
        backgroundColor: "#e8e8e8",
        textDecoration: "2px solid underline #febe68",
      }}
    >
      Here's a glimpse into the diverse roles that drive our mission:
    </Typography>
  );
  const aboutUsBulletPoints = [
    {
      title: "Leadership",
      description:
        "Guided by experienced leaders who bring strategic vision and industry insight.",
      color: "tertiary",
    },
    {
      title: "Specialist Consultants",
      description:
        "In our team, you'll find dedicated and passionate industry specialists. Each consultant is deeply committed to their respective sectors, ensuring a superior level of service that stems from a wealth of knowledge and unwavering enthusiasm.",
      color: "tertiary",
    },
  ];
  const aboutUsBulletPoints2 = [
    {
      title: "Support Staff",
      description:
        "Our dedicated support staff guarantee efficient operations, enhance the contractor experience and offers assistance to both clients and candidates for a more streamlined process.",
      color: "tertiary",
    },
    {
      title: "Collaborative Effort",
      description:
        "Together, we cultivate a collaborative atmosphere, guaranteeing a smooth and cohesive experience for everyone engaged in the recruitment process.",
      color: "tertiary",
    },
  ];

  return (
    <ScopedCssBaseline>
      <Box>
        <Grid container sx={{ backgroundColor: "#ffffff" }}>
          <ImageBanner image={dam} title="About Us" />
          <DecorativeBanner color="black" />
          <Typography
            variant="h4"
            textAlign="center"
            p={1}
            width="100%"
            sx={{
              fontFamily: "Poppins",
              // fontWeight: 600,
              textDecoration: "underline #febe68",
            }}
          >
            Be Better Than Yesterday
            {/* make a card component */}
          </Typography>
          <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
            {/*TODO  add logo iin corner */}

            <Typography
              variant="h6"
              px={8}
              py={3}
          
              sx={{
                color: "#1e1e1e",
                width: "100%",
                textAlign: `center`,
              }}
            >
              Williams Management Consulting was founded to change the way the
              industry approaches recruitment.
              <br />
              <br />
              Specialising in agile and bespoke resourcing solutions, we focus
              on serving the M&E Construction, Ground Engineering, and Rail
              Systems sectors.
              <br />
              Our commitment is to cultivate meaningful partnerships with
              clients and candidates across the UK, Europe & Middle East.
            </Typography>
            <Typography
                 px={8}
                 py={3}
                 pb={10}
              variant="h6"
              sx={{
                color: "#1e1e1e",
                width: "100%",
                textAlign: `center`,
              }}
            >
              At Williams Management Consulting, we believe in the power of
              exceptional individuals coming together to create extraordinary
              outcomes.
              <br /> Our dedicated team is the driving force behind our
              commitment to redefining the recruitment experience.
            </Typography>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent={"center"}
              px={4}
              py={4}
              sx={{
                backgroundColor: "#e8e8e8",
              }}
            >
              {aboutUsSubheader}
              <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} p={4}>
                <BulletPointList bulletPointContent={aboutUsBulletPoints} />
              </Grid>

              {/* BLOCK! */}
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                p={4}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <BulletPointList bulletPointContent={aboutUsBulletPoints2} />
              </Grid>
            </Grid>
            <DecorativeBanner color="black" />
            <Typography
              variant="h5"
              textAlign="center"
              p={4}
              sx={{
                color: "#1e1e1e",
                fontFamily: "Poppins",
                fontWeight: 600,

                width: "100%",
                textAlign: "center",
                backgroundColor: "#f4f4f4",
              }}
            >
              <Typography variant="h5"
              textAlign="center" p={1} fontWeight={600}>Enhance your recruitment journey with us.</Typography>
                
                <br/>
              <Button
                variant="contained"
                color="tertiary"
                href={"/ContactUs"}
                size="large"
                sx={{ ":hover": { textDecoration: "2px solid underline" } }}
              >
                Contact Us
              </Button>

              {/* make a card component */}
            </Typography>
            <DecorativeBanner color="lightYellow" />

            <Grid
              container
              item
              xs={12}
              padding={3}
              justifyContent={"center"}
              sx={{ backgroundColor: "#ffffff" }}
            >
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5} textAlign="center">
                <Link
                  href="https://goo.gl/maps/pzw6wiyJD9rcdWkTA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                  <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                  <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                  <br></br>
                  <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                  <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "0.7rem",
                      margin: 0,
                      fontFamily: "Poppins",
                      fontWeight: 600,
                    }}
                  >
                    5/5 <br></br>Google
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5} textAlign="center">
                <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                <br></br>
                <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "0.7rem",
                    margin: 0,
                    fontFamily: "Poppins",
                    fontWeight: 600,
                  }}
                >
                  5/5 <br></br>TrustPilot
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <DecorativeBanner color="black" />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            p={4}
            sx={{
              backgroundColor: "#e8e8e8",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "space-evenly",
                xl: "space-evenly",
              },
            }}
          >
            <Typography
              variant="h5"
              textAlign="center"
              px={2}
              width={"100%"}
              sx={{
                fontFamily: "Poppins",
                fontWeight: 600,
                textDecoration: "underline #ffc85c",
              }}
            >
              Our Specialisms
            </Typography>
            <SpecialismsCards />
          </Grid>
        </Grid>
      </Box>
    </ScopedCssBaseline>
  );
};
export default AboutUs;
