import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./containers/Footer/Footer";
import Header from "./containers/Header/Header";
import Home from "./pages/Home/Home";
import Jobs from "./pages/Jobs/Jobs";
import ContactUs from "./pages/Contact-Us/ContactUs";
import DecorativeBanner from "./components/DecorativeBanner/DecorativeBanner";
import AboutUs from "./pages/AboutUs/AboutUs";
import Specialisms from "./pages/Specialisms/Specialisms";
import MeConstruction from "./pages/Specialisms/MeConstruction/MeConstruction";
import RailSystems from "./pages/Specialisms/RailSystems/RailSystems";
import GroundEngineering from "./pages/Specialisms/GroundEngineering/GroundEngineering";
const App = () => {
  return (
    <>
      <Header />
      <DecorativeBanner color="black" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Vacancies">
          <Route index element={<Jobs />} />
          {/* <Route path=":id" element={<Job />} /> */}
        </Route>
        <Route path="/Specialisms">
          <Route index element={<Specialisms />} />
          <Route path="M-And-E-Construction" element={<MeConstruction />} />
          <Route path="Rail-Systems" element={<RailSystems />} />
          <Route path="Ground-Engineering" element={<GroundEngineering />} />
        </Route>
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <DecorativeBanner />
      <Footer />
    </>
  );
};

export default App;
