import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const BulletPointList = ({ bulletPointContent, subheader }) => {
  const bulletPointListItems = bulletPointContent.map(
    ({ title, description, color }) => {
      return (
        <ListItem disableGutters>
          <ListItemIcon sx={{ justifyContent: "center" }}>
            <CircleIcon fontSize="small" sx={{ padding: 0 }} color={color} />
          </ListItemIcon>
          <ListItemText primary={title} secondary={description} />
        </ListItem>
      );
    }
  );
  return (
    <List>
      <ListSubheader disableSticky sx={{ backgroundColor: "unset" }}>
        {subheader}
      </ListSubheader>
      {bulletPointListItems}
    </List>
    /* <List dense>
        <ListItem>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText
            primary="Single-line item"
            secondary={"Secondary text"}
          />
        </ListItem>
        ,
      </List> *

 
      {/* <List>
        <ListItem>
          <ListItemText
            primary="Geotechnical Engineering:"
            secondary="Ground Anchors, Soil Nailing, Permeation Grouting, Compaction Grouting, Fractural Grouting, Jet Grouting, Drilling Services, Slurry Walls. "
          />
        </ListItem>
      </List> */
  );
};
export default BulletPointList;
