import { Box, Grid, ScopedCssBaseline } from "@mui/material";
import ImageBanner from "../../containers/ImageBanner/ImageBanner";
import welder from "../../../src/images/assets/welding.jpeg";
import RailSystemsImage from "../../../src/images/assets/tunnel.jpeg";
import SectorTitleCard from "./SectorTitleCard/SectorTitleCard";
import DecorativeBanner from "../../components/DecorativeBanner/DecorativeBanner";
import MEConstructionImage from "../../../src/images/assets/MEConstruction.jpg";
import GroundEngineeringImage from "../../../src/images/assets/groundEngineering.jpg";
const Specialisms = () => {
  return (
    <ScopedCssBaseline>
      <Box>
        <Grid container>
          <ImageBanner image={welder} title="Specialisms" />
          <DecorativeBanner color="lightYellow" />
        </Grid>
        <Grid p={4}></Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          p={1}
          flexDirection={["column", "row", "row", "row", "row"]}
          justifyContent={["center"]}
          alignItems={["center"]}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={4}
            xl={3}
            px={[3, 2, 1, 1, 1]}
            width={"100%"}
            flexGrow={1}
          >
            <SectorTitleCard
              image={MEConstructionImage}
              sectorTitle="M&E Construction"
              highlightColor={"#ff8b53"}
              URLSuffix={"M-And-E-Construction"}
              themeColorName={"secondary"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={4}
            xl={3}
            px={[3, 2, 1, 1, 1]}
            width={"100%"}
          >
            <SectorTitleCard
              image={GroundEngineeringImage}
              sectorTitle="Ground Engineering"
              highlightColor={"#febe68"}
              URLSuffix={"Ground-Engineering"}
              themeColorName={"tertiary"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={4}
            xl={3}
            px={[3, 2, 1, 1, 1]}
            width={"100%"}
          >
            <SectorTitleCard
              image={RailSystemsImage}
              sectorTitle="Rail Systems"
              highlightColor={"rgb(84, 219, 163)"}
              URLSuffix="Rail-Systems"
              themeColorName={"quaternary"}
            />
          </Grid>
        </Grid>
      </Box>
    </ScopedCssBaseline>
  );
};

export default Specialisms;
