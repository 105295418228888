import "./ContactInformation.scss";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
const ContactInformation = ({ hasHeader }) => {
  return (
    <div className="contact-information">
      {hasHeader ? (
        <a className="contact-information__header-link" href="/contact-us">
          <h5 className="contact-information__header">Contact Us</h5>
        </a>
      ) : (
        <div />
      )}
      <FaMapMarkerAlt className="contact-information__icon contact-information__icon--map-marker" />
      <p className="contact-information__text contact-information__text--address">
        4th Floor 14 Museum Place <br></br> City Centre <br></br> Cardiff
        <br></br>Wales <br></br>CF10 3BH
      </p>
      <BsFillTelephoneFill className="contact-information__icon" />
      <p className="contact-information__text">{"+"}44&nbsp;(0)2922 700 200</p>
      <MdEmail className="contact-information__icon" />
      <p className="contact-information__text">
        <a href="mailto:info@WilliamsManagement.Consulting">
          info@WilliamsManagement.Consulting
        </a>
      </p>
    </div>
  );
};
export default ContactInformation;
