import {
  Card,
  Typography,
  CardMedia,
  CardContent,
  CardHeader,
  CardActionArea,
  Button,
  Container,
} from "@mui/material";

import React from "react";

const SpecialismCard = ({
  headerText,
  image,
  specialismColor,
  contentText,
  specialismPageUrl,
}) => {
  const titleTypographyProps = {
    variant: "h6",
    display: "inline-block",
    lineHeight: "1.2",
    align: "right",
    padding: 0,
    margin: 0,
    fontWeight: 600,
    fontFamily: "Poppins",
  };
  const cardTextContent = (contentText) => {
    return (
      <Container
        disableGutters
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "space-between",
          padding: 0.5,
        }}
      >
        <Typography
          align="justify"
          variant="body2"
          paragraph
          textOverflow="ellipsis"
          overflow="hidden"
          sx={{
            paddingBottom: 0.5,
            maxWidth: "100%",
            fontFamily: "Poppins",
            fontWeight: 300,
            fontSize: "0.8rem",
            marginBottom: 0,
          }}
        >
          {contentText}
        </Typography>
        <Button
          href={specialismPageUrl}
          variant="contained"
          color="inherit"
          disableRipple
          size="small"
          sx={{
            position: "relative",
            bottom: 0,
            right: 0,
            textDecoration: `1px solid underline ${specialismColor}`,
            padding: "auto",
          }}
        >
          Read More
        </Button>
      </Container>
    );
  };
  return (
    <Card
      elevation={5}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "10px",
        padding: 0.5,
        backgroundColor: "#e8e8e8",
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: `2px 2px 11px ${specialismColor}`,
        },
      }}
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          borderRadius: "10px",
          border: `2px solid ${specialismColor}`,
        }}
      >
        <CardMedia
          image={image}
          sx={{
            maxHeight: "50%",
            height: "10rem",
            width: "100%",
            flexGrow: 1,
            borderRadius: "10px 10px 0 0",
            position: "relative",
          }}
        >
          <CardHeader
            title={headerText}
            sx={{
              maxWidth: "90%",
              textAlign: "right",
              minWidth: "50%",
              wordWrap: "break-word",
              backgroundColor: "#e8e8e8",
              borderRadius: "0 10px 0 10px",
              position: "absolute",
              top: 0,
              right: 0,
              borderLeft: `2px solid ${specialismColor}`,
              borderBottom: `2px solid ${specialismColor}`,
              padding: 0.5,
            }}
            titleTypographyProps={titleTypographyProps}
          />
        </CardMedia>
        <CardContent
          component="div"
          children={cardTextContent(contentText)}
          sx={{
            width: "100%",
            flexGrow: 1,
            backgroundColor: "#ffffff",
            borderRadius: "0 0 10px 10px",
            padding: 0.5,
          }}
        ></CardContent>
      </CardActionArea>
    </Card>
  );
};
export default SpecialismCard;
