import { Grid, Typography } from "@mui/material";

const ReviewQuoteCard = ({ specialismName, jobTitle, quote, color }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        backgroundColor: "#ffffff",
        padding: 3,
      }}
    >
      <Typography
        align="center"
        variant="h6"
        sx={{
          textDecoration: `1px solid underline ${color}`,
          fontFamily: "Poppins",
          fontWeight: 300,
        }}
      >
        {specialismName}
      </Typography>
      <Typography
        align="center"
        sx={{
          textDecoration: `1px solid underline ${color}`,
          fontFamily: "Poppins",
          fontWeight: 300,
          paddingBottom: 1,
        }}
      >
        {jobTitle}
      </Typography>
      <Typography
        align="justify"
        sx={{
          fontFamily: "Poppins",
          fontSize: "0.7rem",
          fontWeight: 600,
          fontStyle: "italic",
        }}
      >
        "{quote}"
      </Typography>
    </Grid>
  );
};
export default ReviewQuoteCard;
