import "./NavMenu.scss";

import SiteLinksList from "../SiteLinksList/SiteLinksList";

const NavMenu = ({ pageLocation }) => {
  return (
    <nav className={`navigation-menu navigation-menu--${pageLocation}`}>
      <SiteLinksList pageLocation={pageLocation} />
    </nav>
  );
};
export default NavMenu;
