import { useMediaQuery } from "@mui/material";
import "./HexagonSVG.scss";

const HexagonSVG = ({ color, name }) => {
  const hexagonClassName = `hexagon-image hexagon-image--${name}`;
  const meedium = useMediaQuery("(min-width:500px)");
  const hasReducedHeight = meedium ? "256px" : "200px";
  return (
    <svg
      className={hexagonClassName}
      width="256px"
      height={`${hasReducedHeight}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          height="100%"
          width="100%"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};
export default HexagonSVG;
