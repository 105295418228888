import { Grid, Typography, useMediaQuery, Link } from "@mui/material";
import { StarSharp } from "@mui/icons-material";
import tunnel from "../../images/assets/tunnel.jpeg";

const IntroductionContainer = () => {
  const isLargeScreen = useMediaQuery("(min-width: 1440px)");
  const largeTextAlignment = isLargeScreen ? "left" : "center";
  const titleTextSize = isLargeScreen ? "h5" : "body2";
  return (
    <Grid
      className="jimmm"
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      justifyContent={"center"}
      padding={[2, 2, 2, 3, 5]}
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${tunnel})`,
        backgroundBlendMode: "multiply",
        backgroundPosition: "center",
        backgroundSize: "cover",
        alignItems: "center",
      }}
    >
      <Grid item container xs={12} sm={12} md={9} lg={9} xl={7} padding={2}>
        <Typography
          variant={titleTextSize}
          sx={{
            color: "#ffffff",
            width: "100%",
            textAlign: `${largeTextAlignment}`,
            margin: 0,
            padding: "0.5rem",
          }}
        >
          Williams Management Consulting was founded to change the way the
          industry approaches recruitment. Our commitment is to cultivate
          meaningful partnerships with clients and candidates across the UK,
          Europe & Middle East.
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={1}
        xs={8}
        sm={8}
        md={3}
        lg={3}
        xl={3}
        padding={1}
        sx={{
          backgroundColor: "rgb(255, 200, 92, 0.7)",
          borderRadius: "1rem",
          alignItems: "center",
        }}
      >
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} textAlign="center">
          <Link
            href="https://goo.gl/maps/pzw6wiyJD9rcdWkTA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
            <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
            <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
            <br></br>
            <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
            <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "0.7rem",
                margin: 0,
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            >
              5/5 <br></br>Google
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} textAlign="center">
          <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
          <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
          <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
          <br></br>
          <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
          <StarSharp sx={{ color: "rgb(84, 219, 163)" }} />
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "0.7rem",
              margin: 0,
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
          >
            5/5 <br></br>TrustPilot
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default IntroductionContainer;
