import { Grid } from "@mui/material";
import AnimatedStat from "../../components/AnimatedStat/AnimatedStat";

const StatsContainer = () => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ justifyContent: "center", alignItems: "baseline" }}
      padding={[0, 1, 2, 3, 5]}
    >
      <AnimatedStat
        statname="Year Established"
        statValue={2020}
        startValue={2000}
        statColor="#ff8b53"
      />
      <AnimatedStat
        statname="Exclusive and Retained Success Rate"
        statValue={98}
        startValue={50}
        statColor="#ff8b53"
        suffix="%"
      />
      <AnimatedStat
        statname="CV's : Interviews : Offer"
        suffix={":2:1"}
        statValue={3}
        statColor="#ff8b53"
      />
    </Grid>
  );
};
export default StatsContainer;
