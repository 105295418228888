import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FilterAccordion = ({
  title,
  filterOptions,
  checkedOptions,
  onChange,
  isExpandedProp,
}) => {
  const handleToggle = (value) => () => {
    const currentIndex = checkedOptions.indexOf(value);
    const newChecked = [...checkedOptions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onChange(newChecked);
  };


  const formatPropertyName = (propertyName) => {
    // Capitalize the first letter
    const capitalized =
      propertyName.charAt(0).toUpperCase() + propertyName.slice(1);

    // Add a space before capital letters (except the first one)
    const formatted = capitalized.replace(/([A-Z])/g, " $1");

    return formatted;
  };
  const filterHeader = formatPropertyName(title);
  return (
    <Accordion
      defaultExpanded={isExpandedProp}
      fullWidth
      sx={{ width: "100%", border: "1px solid #ff8b53" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: "100%" }}>
        <Typography variant="body1">{filterHeader}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {filterOptions.map((option, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedOptions.indexOf(option) !== -1}
                onChange={handleToggle(option)}
                color="secondary"
              />
            }
            label={option}
            key={index}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
export default FilterAccordion;
