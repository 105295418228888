import Banner from "../../components/Banner/Banner";
import Layout from "../../components/Layout/Layout";
import { useState } from "react";
import DropDownMenu from "../../components/DropdownMenu/DropdownMenu";
import "./Header.scss";
import siteData from "../../data/siteInputData";
const Header = () => {
  const [isDropDownActive, setisDropDownActive] = useState(false);
  const { navigationLinksList } = siteData;
  return (
    <Layout>
      <div className="header">
        <Banner
          dropdownState={isDropDownActive}
          setdropDownState={setisDropDownActive}
        />
        {isDropDownActive && (
          <DropDownMenu
            onClick={() => setisDropDownActive(!isDropDownActive)}
            dropdownLinkList={navigationLinksList}
            dropdownID="header"
          />
        )}
      </div>
    </Layout>
  );
};
export default Header;
