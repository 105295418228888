import "./MyButton.scss";
import { Link } from "react-router-dom";

const MyButton = ({
  buttonText,
  buttonSize,
  jobSpecialism,
  onClick,
  isNavButton,
  linkId,
}) => {
  const sizeClass = buttonSize ? `myButton--${buttonSize}` : "";
  const colorClass = jobSpecialism ? `myButton--${jobSpecialism}` : "";
  const buttonClass = `myButton ${sizeClass} ${colorClass}`.trim();
  const isNavigationButton = isNavButton ? (
    <Link to={`${linkId}`}>{buttonText}</Link>
  ) : (
    buttonText
  );
  return (
    <button className={buttonClass} onClick={onClick}>
      {isNavigationButton}
    </button>
  );
};
export default MyButton;
