import Grid from "@mui/material/Grid";
import "./DecorativeBanner.scss";

const DecorativeBanner = ({ color }) => {
  const bannerClass = color
    ? `decorative-banner--${color}`
    : "decorative-banner";
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      height="0.2rem"
      className={bannerClass}
    ></Grid>
  );
};

export default DecorativeBanner;
