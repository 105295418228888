import SiteLinksList from "../SiteLinksList/SiteLinksList";
import "./DropDownMenu.scss";

const DropDownMenu = ({ dropdownID, onClick }) => {
  const dropdownClassName = dropdownID
    ? `dropdown-menu dropdown-menu--${dropdownID}`
    : `dropdown-menu`;

  return (
    <div onClick={onClick} className={dropdownClassName}>
      <SiteLinksList pageLocation="dropdown-menu" />
    </div>
  );
};
export default DropDownMenu;
