import tunnel from "../../../images/assets/tunnel.jpeg";

import {
  Box,
  Grid,
  ScopedCssBaseline,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import ImageBanner from "../../../containers/ImageBanner/ImageBanner";

import DecorativeBanner from "../../../components/DecorativeBanner/DecorativeBanner";
import BulletPointList from "../GroundEngineering/bulletpoints";
import { JobsCard } from "../../../components/JobCard/JobsCard";
import { dummyData } from "../../../dummyData";
import CountUp from "react-countup";
import { shortenDescription } from "../specialismsUtility";
export const RailSystems = () => {
  const theme = useTheme();
  const specialismJobs = dummyData.filter((item) =>
    item?.sector?.toLowerCase().includes("rail")
  );
  const isLargeScreen = useMediaQuery("(min-width: 1440px)");
  const specialismJobCards = specialismJobs
    .map((item) => (
      <JobsCard
        key={item?.jobReference}
        jobTitle={item?.jobTitle}
        vacancyType={item?.vacancyType}
        specialism={item?.sector}
        location={item?.location}
        salaryLow={item?.salaryLow}
        salaryHigh={item?.salaryHigh}
        benefits={item?.benefits}
        description={item?.description}
        isSummaryCard
        gridBackgroundColor="#f4f4f4"
        cardBackgroundColor="#ffffff"
      />
    ))
    .slice(0, isLargeScreen ? 4 : 3);

  const isSmallerMobile = useMediaQuery("(max-width: 426px)");
  const getDescription = (description) => {
    return isSmallerMobile ? shortenDescription(description, 150) : description;
  };
  const projectsSubheader = (
    <Typography
      variant="h5"
      sx={{
        color: "#1e1e1e",
        width: "100%",
        fontWeight: 600,
        // textAlign: `justify`,
        padding: "0.5rem",
        marginTop: "0.3rem",
        backgroundColor: "#e8e8e8",
        textDecoration: "2px solid underline #54dba3",
      }}
    >
      Types of projects we've supported our clients deliver include:
    </Typography>
  );
  const projectBulletPoints = [
    {
      title: "S&T",
      description: getDescription(
        "GSM-R Systems, FTN Systems, Radio Systems, Wi-Fi Solutions, CCTV, PA & PAVA, Access Controls, Passenger Communication Systems, IP Networks and Safety Critical Systems."
      ),
      color: "quaternary",
    },
    {
      title: "Electrification",
      description: getDescription(
        "Overhead Line Equipment, Laying Cables, Wiring, Substations, HVDC Converters, Switchgear, Power Upgrades and Extensions."
      ),
      color: "quaternary",
    },
    {
      title: "P-Way",
      description:
        "New Track, Extensions, Level Crossing, Rails and upgrading sleepers & ballasts.",
      color: "quaternary",
    },
    {
      title: "Civils",
      description:
        "Viaduct, Platforms, Structures, Earthwork, Deep Drainage, Overbridge, Underbridge, Trenching and Lineside-civils.",
      color: "quaternary",
    },
  ];

  const competenciesSubheader = (
    <Typography
      variant="h5"
      px={4}
      sx={{
        color: "#1e1e1e",
        width: "100%",
        fontWeight: 600,
        marginTop: "0.3rem",
        backgroundColor: "#f4f4f4",
        textDecoration: "2px solid underline #54dba3",
      }}
    >
      Specialist competencies we can provide:
    </Typography>
  );
  const competency2BulletPoints = [
    {
      title: "Pre-Construction",
      description:
        "Estimating and Pre-Construction professionals of all seniorities.",
      color: "quaternary",
    },
    {
      title: "Engineering",
      description:
        "CRE, CEM, Site, Design and Technical Engineering and Management professionals of all seniorities.",
      color: "quaternary",
    },
  ];
  const competencyBulletPoints = [
    {
      title: "Commercial & Quantity Surveying",
      description:
        "Quantity Surveyors and Commercial Management professionals of all seniorities.",
      color: "quaternary",
    },
    {
      title: "Operations",
      description:
        "Site Managers, Sub and Site Agents. Operations, Region, Project and Construction Management professionals of all seniorities including Managing Directors",
      color: "quaternary",
    },
  ];

  return (
    <ScopedCssBaseline>
      <Box>
        <Grid container>
          <ImageBanner image={tunnel} title="Rail Systems" />
          <DecorativeBanner color="green" />
          <Grid
            className="jimmm"
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            // justifyContent={"center"}
            sx={{
              alignItems: "center",
              backgroundColor: "#e8e8e8",

              padding: {
                xs: 2,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 3,
              },
              flexDirection: "column",
              flexWrap: "nowrap",
            }}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              p={[1, 3, 4]}
              justifyContent={"space-around"}
            >
              <Typography
                variant={isLargeScreen ? "h5" : "h6"}
                sx={{
                  color: "#1e1e1e",
                  textAlign: "center",
                  margin: 0,
                }}
              >
                Williams Management Consulting provide white collar recruitment
                services to specialist Signalling & Telecoms, Electrification,
                Permanent-way & Civil Engineering Rail sub-contractors, main
                contractors and consultancies across the UK Network Rail, London
                Underground, TFL & Light-Urban (Metro) networks.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              p={[0, 3, 0]}
              py={1}
            >
              <Grid item container xs={12} sm={12} md={9} lg={9} xl={9}>
                <BulletPointList
                  bulletPointContent={projectBulletPoints}
                  subheader={projectsSubheader}
                />
              </Grid>
              {/* lvie job count block statr */}
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                justifyContent={"space-around"}
                alignItems={"center"}
                p={4}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={10}
                  md={12}
                  lg={12}
                  xl={12}
                  // px={1}
                  sx={{
                    alignItems: "center",
                    backgroundColor: "#54dba3",
                  }}
                >
                  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        borderRadius: "1.8rem",
                        justifyContent: "center",
                        alignItems: "baseline",
                        backgroundColor: "white",
                      }}
                      padding={[0, 1, 2, 3, 3]}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        padding={1}
                        wrap="wrap"
                        sx={{
                          justifyContent: "center",
                          alignItems: "stretch",

                          position: "relative",
                          height: "100%",
                          maxHeight: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.5rem",
                            width: "100%",
                            textAlign: "center",
                            padding: 0,
                            margin: 0,
                            fontFamily: "Poppins",
                            fontWeight: 300,
                          }}
                        >
                          <CountUp
                            start={0}
                            end={specialismJobs?.length ?? 7}
                            duration={7}
                            enableScrollSpy
                            scrollSpyDelay={3}
                            useGrouping={false}
                          />
                        </Typography>
                        <Typography
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            color: "#1e1e1e",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                          }}
                        >
                          Currently Advertised Vacancies in Rail Systems
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* lvie job count block end */}
          </Grid>
          <DecorativeBanner color="green" />

          <Typography
            variant="h5"
            textAlign="center"
            p={4}
            sx={{
              color: "#1e1e1e",
              fontFamily: "Poppins",
              fontWeight: 600,
              textDecoration: "underline #54dba3",
              width: "100%",
              textAlign: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <Button
              variant="contained"
              color="quaternary"
              href={"/Vacancies"}
              size="large"
              sx={{ ":hover": { textDecoration: "2px solid underline" } }}
            >
              See All Live Vacancies
            </Button>

            {/* make a card component */}
          </Typography>

          <DecorativeBanner color="green" />

          {/* <DecorativeBanner color="black" /> */}
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent={"center"}
            px={[1, 4]}
            py={8}
            sx={{
              backgroundColor: "#f4f4f4",
            }}
          >
            {competenciesSubheader}
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              p={[1, 4]}
            >
              <BulletPointList bulletPointContent={competencyBulletPoints} />
            </Grid>

            {/* BLOCK! */}
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              p={[1, 4]}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <BulletPointList bulletPointContent={competency2BulletPoints} />
            </Grid>
          </Grid>

          <DecorativeBanner color="green" />
          <Typography
            variant="h5"
            textAlign="center"
            p={4}
            sx={{
              color: "#1e1e1e",
              fontFamily: "Poppins",
              fontWeight: 600,

              width: "100%",
              textAlign: "center",
              backgroundColor: "#ffffff",
            }}
          >
            Looking to elevate your team? <br />
            Or are you a specialist in this area who’s curious to see what’s out
            there?
            <br />
            <Button
              variant="contained"
              color="quaternary"
              href={"/ContactUs"}
              size="large"
              sx={{
                marginTop: "0.5rem",
                ":hover": { textDecoration: "2px solid underline" },
              }}
            >
              Contact Us
            </Button>
            {/* make a card component */}
          </Typography>

          {useMediaQuery(theme.breakpoints.up("md")) && (
            <>
              <DecorativeBanner color="green" />
              <Grid
                container
                item
                alignItems={"flex-end"}
                justifyContent={"center"}
                p={3}
                sx={{ backgroundColor: "#f4f4f4" }}
              >
                <Typography
                  variant="h5"
                  textAlign="center"
                  px={2}
                  sx={{
                    color: "#1e1e1e",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    textDecoration: "underline #54dba3",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Some Of Our Live Jobs In This Specialism
                  {/* make a card component */}
                </Typography>
                {specialismJobCards}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </ScopedCssBaseline>
  );
};

export default RailSystems;
