import { Grid, useMediaQuery } from "@mui/material";
import ReviewQuoteCard from "../../components/ReviewQuoteCard/ReviewQuoteCard";

// refactor to a map based on data file
const ReviewQuotes = () => {
  const isBelowSmBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        {isBelowSmBreakpoint ? (
          <Grid item xs={12} sm={6} md={6} lg={12} xl={12} padding={1}>
            <ReviewQuoteCard
              specialismName="Ground Engineering Sector"
              jobTitle="Technical Director"
              quote="Our relationship is more of a partnership than that of an agency and client. The appreciation for WMC extends across the company, due to their determination to match us with the right people in terms of skills and cultural fit. Along with having confidence in achieving speedy and appropriate results, we appreciate the time they have taken to cultivate the relationship"
              color="#febe68"
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} padding={1}>
              <ReviewQuoteCard
                specialismName="Ground Engineering Sector"
                jobTitle="Technical Director"
                quote="Our relationship is more of a partnership than that of an agency and client. The appreciation for WMC extends across the company, due to their determination to match us with the right people in terms of skills and cultural fit. Along with having confidence in achieving speedy and appropriate results, we appreciate the time they have taken to cultivate the relationship"
                color="#febe68"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} padding={1}>
              <ReviewQuoteCard
                specialismName="M&E Construction Sector"
                jobTitle="M&E Quantity Surveyor"
                quote="After a few short conversations it became clear Alex had a genuine and vested interest in finding a role that was right for both my personal life and career aspirations. Alex’s honesty, integrity, experience, professionalism and positive relationships with an extensive client base are further reasons I will recommend WMC to anyone"
                color="#ff8b53"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} padding={1}>
              <ReviewQuoteCard
                specialismName="Ground Engineering Sector"
                jobTitle="Contracts Engineer"
                quote="Alex’s dedication, expertise and unwavering support have been instrumental in helping me secure a fantastic job opportunity. His ability to understand my skills, qualifications and aspirations coupled with his extensive industry knowledge and network, enabled me to identify the perfect match for my career goals"
                color="#febe68"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} padding={1}>
              <ReviewQuoteCard
                specialismName="Rail Systems Sector"
                jobTitle="Project Director"
                quote="Having always struggled to find highly technical Engineers at CRE & CEM level. WMC have removed this stress for us. Following an initial consultation, it was clear that WMC listened to our prior issues and proposed a proactive solution to move forward to overcome these consistent challenges. This has resulted in 3 contract and 2 permanent hires during 2023. We look forward to continuing to collaborate in 2024 and beyond."
                color="rgb(84, 219, 163)"
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ReviewQuotes;
