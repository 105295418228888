import "./SpecialismHexagon.scss";
import HexagonSVG from "../HexagonSVG/HexagonSVG";
import { Box } from "@mui/material";

const SpecialismHexagon = ({ title, tagline, color }) => {
  const hexagonImageClassModifier = title.toLowerCase();
  return (
    <Box>
      <div className="hexagon">
        <HexagonSVG color={color} name={hexagonImageClassModifier} />
        <div className="hexagon__text-container">
          <h4 className={`hexagon__title hexagon__title--${title}`}>{title}</h4>
          <h5 className="hexagon__sub-title">{tagline}</h5>
        </div>
      </div>
    </Box>
  );
};
export default SpecialismHexagon;
